import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import { Col, Row, Form } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getpostcarelistAction } from "../Redux/Actions/user/salon";
export default function PreCare() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const [precare, setPrecare] = useState({});

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getpostcarelistAction({ search: search }));
  }, [search]);

  let postcarelistData = useSelector((state) => state?.myaccount?.postcarelist);

  postcarelistData = postcarelistData.filter((object) => {
    var userIdIncluded = object?.salonActiveArr.find(
      (obj) => obj?.salon_id === localStorage.getItem("userId")
    );

    if (
      (userIdIncluded?.status == 1 && object?.admin_id?._id != undefined) ||
      (object?.salon_id == localStorage?.getItem("userId") &&
        object?.status == 1)
    ) {
      return object;
    }
  });

  return (
    <Layout>
      {" "}
      <section className="margin-top-set">
        <Row>
          <Col lg={6}>
            <div className="cmn-sec-left">
              <h2>Pre & Post Treatment Care </h2>
              <p>
                View all your available default pre & post care forms
                <br /> Send to clients via email prior to their salon visit{" "}
                <br />
                To add a new one to your default list, please head to the Pre &
                Post Care Settings
              </p>
            </div>
          </Col>
          <Col lg={6} className="d-flex justify-content-end align-items-start">
            <Link to="/precare-presents" className="consult-btn">
              Find More Consultation Options in Settings
            </Link>
          </Col>
        </Row>
      </section>
      {/* tab-links */}
      {/* search header */}
      <section className="search-header mb-4">
        <Row className="justify-content-end">
          <Col
            xs={12}
            md={5}
            sm={5}
            className="d-flex justify-content-end align-items-center"
          >
            <div className="search-input">
              <InputGroup>
                <InputGroup.Text className="group-box-search">
                  <img
                    src={require("../assets/img/search.svg").default}
                    alt="search"
                  />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search...."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </InputGroup>
            </div>
          </Col>
          <Col
            xs={12}
            md={2}
            sm={7}
            className="d-flex justify-content-end align-items-start"
          >
            <div className="button-link">
              <NavLink
                to="#"
                className="white-box-link  create_white-box-link d-block"
                onClick={handleShow2}
              >
                Send Advice
              </NavLink>
            </div>
          </Col>
        </Row>
      </section>
      {postcarelistData?.map(function (object, i) {
        return (
          <section className="search-list-part">
            <div className="search-list-box">
              <Row className="d-flex align-items-end" key={i}>
                <Col xs={12} md={5} sm={6}>
                  <div className="search-content-left">
                    <h2>{object?.treatmentname}</h2>
                    <div className="sub-heading">
                      Pre & Post Treatment Care Advice
                    </div>
                    <p>{object?.description}</p>
                  </div>
                </Col>
                <Col xs={12} md={7} sm={6}>
                  <div className="search-content-right">
                    <NavLink
                      as={Link}
                      //to="/send_consulation"
                      className="white-box-link-outline "
                    >
                      Send to Client
                    </NavLink>

                    <button
                      className="green-box-link-fill"
                      onClick={() => {
                        handleShow();

                        setPrecare(object);
                      }}
                    >
                      View
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        );
      })}
      <Modal show={show} onHide={handleClose} className="client-consult">
        <Modal.Body>
          <div className="treat-box h-100">
            <h2 className="text-center">{precare.treatmentname}</h2>
            <Row>
              <Col lg={6}>
                <div className="left-instruct">
                  <h3>Pre Care Instructions</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>
                      {precare.pre_care_advice}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="right-instruct">
                  <h3>Post Care Instructions</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>
                      {precare.after_care_advice}
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="right-instruct text-center">
                  <h3>Notes</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>{precare.notes}</p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                {" "}
                <div className="d-flex justify-content-center left-instruct outer">
                  <button type="button" className="mt-1">
                    View / Print
                  </button>
                </div>
              </Col>
              <Col lg={6}>
                {" "}
                <div className="d-flex justify-content-center right-instruct">
                  <button type="button" className="mt-1">
                    Send to Client
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show2}
        onHide={handleClose2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="client-consult precare"
      >
        <Modal.Body closeButton>
          <div className="client_consulation">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <label>Search for clients</label>
                  <input
                    name=""
                    type="search"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Type to search"
                  />
                </div>
                <div className="col-lg-12">
                  <label>Search for Advice</label>
                  <input
                    name=""
                    type="search"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Type to search"
                  />
                </div>
              </div>
              {/* 
              <div className="col-lg-12">
                <Row>
                  <Col lg={6} xs={6}>
                    <div className="cancel-btn">
                      <button type="button" class="btn mb-3">
                        Cancel
                      </button>
                    </div>
                  </Col>
                  <Col lg={6} xs={6}>
                    <div className="submit-btn">
                      <button type="submit" class="btn mb-3">
                        Send Client
                      </button>
                    </div>
                  </Col>
                </Row>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
