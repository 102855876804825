import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import AppointmentModal from "../components/Modals/AppointmentModal";
import ConsultationModal from "../components/Modals/ConsultationModal";
import InvoiceModal from "../components/Modals/InvoiceModal";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { parseISO, format, isValid } from "date-fns";
import Select from "react-select";
import {
  clientupdateinformationAction,
  consultationformlists,
  fetchAllAppointments,
  fetchClientById,
  fetchCompletedConsultation,
  filedeleteAction,
  medicalhistoryquestionAction,
  salondeletenotesAction,
  salonfetchClientsAction,
  salonupdateClientAction,
  salonupdatenotesAction,
  updatemedicalhistoryAction,
} from "../Redux/Actions/user/salon";
import { Decryptedid, Encryptedid } from "../utils/BcruptEncyptid";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Form, ModalFooter, Row } from "react-bootstrap";
import { medicalHistoryData } from "../utils/rawData";
import Layout from "../components/Layout/Layout";
import toast from "react-hot-toast";
import DateOfBirthPicker from "../components/DateOfBirthPicker";
import useGetAddressIO from "../Hooks/getAddressIo";
const ClientView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  var clientId = Decryptedid(atob(id));
  const [medications, setMedications] = useState([{ name: "", dosage: "" }]);
  const [formupdatedata, setformupdatedata] = useState();
  const [dob, setDOB] = useState();
  const [prevImg2, setPrevImg2] = useState("");
  const [profileimage, Setprofileimage] = useState("");
  const [customermedicalhistory, Setcustomermedicalhistory] = useState([]);
  const [noteid, setNotesid] = useState("");
  const [deletednotesid, setDeletednotesid] = useState("");
  const [consultationFormData, setConsultationFormData] = React.useState();
  const [doucumentid, setdoucumentid] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [title, settitle] = useState("");
  const [client_document, setclient_document] = useState("");
  const [document_title, setdocument_title] = useState("");
  const [file, setfile] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShownew, setModalShownew] = React.useState(false);
  const [modalShowvideo, setModalShowvideo] = React.useState(false);
  const [scroll, setScroll] = useState(false);
  const [fileexists, setfileexists] = useState("");
  const { addressData, getAddress } = useGetAddressIO();
  const {
    clientDetail,
    salonClients,
    appointmentHistory,
    upcomingSchedule,
    completed_consultation,
    questionlist,
  } = useSelector((state) => ({
    clientDetail: state.myaccount.clientDetail,
    questionlist: state.myaccount.questionlist,
    salonClients: state.myaccount.salonClients,
    upcomingSchedule: state.myaccount.upcomingSchedule,
    appointmentHistory: state.myaccount.appointmentHistory,
    completed_consultation: state.myaccount.completed_consultation,
  }));

  useEffect(() => {
    dispatch(fetchCompletedConsultation());
    dispatch(consultationformlists()).then((data) => {
      if (data?.payload?.data) {
        setConsultationFormData(data?.payload?.data);

        var totalobject = data?.payload?.data?.data?.filter(function (
          formobject
        ) {
          var userIdIncluded = formobject?.salonActiveArr.find(
            (obj) => obj?.salon_id === localStorage.getItem("userId")
          );
          const hasFormCreatedByAdminPanel = formobject.hasOwnProperty(
            "formcreatedbyadminPanel"
          );

          return (
            (userIdIncluded &&
              userIdIncluded.consulationformstatus == 0 &&
              hasFormCreatedByAdminPanel) ||
            !hasFormCreatedByAdminPanel
          );
        });
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    dispatch(salonfetchClientsAction());
    dispatch(fetchAllAppointments());
    dispatch(medicalhistoryquestionAction());
  }, []);

  useEffect(() => {
    dispatch(fetchClientById(clientId));
  }, [clientId]);

  useEffect(() => {
    Setcustomermedicalhistory(clientDetail?.customermedicalhistory);
    setMedications(clientDetail?.medications);
  }, [clientDetail]);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [newconsultationview, setnewconsultationview] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isHidden, setIsHidden] = useState(false);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const birthDate = moment(clientDetail?.dob);
  const today = moment();
  const calculatedAge = today.diff(birthDate, "years");

  const handleClose2 = () => {
    setShow2(false);
    setdocument_title("");
    setclient_document("");
    setPrevImg2("");
    setfile("");
    setdoucumentid("");
  };

  const handleShow2 = () => setShow2(true);

  //document upload functionality
  const uploadnewdocument = (selectedFile) => {
    if (document_title != "") {
      let formdata = new FormData();
      formdata.append("id", clientDetail._id);
      formdata.append("client_document", file);
      formdata.append("doucumentid", doucumentid);
      formdata.append("document_title", document_title);
      dispatch(salonupdateClientAction(formdata)).then(function () {
        dispatch(fetchClientById(clientDetail._id));
        setdocument_title("");
        setclient_document("");
        setdoucumentid("");
        setPrevImg2("");
        setfile("");
        setShow2(false);
      });
    } else {
      toast.error("Document title is required");
    }
  };

  //notes add functionality
  const addnotes = () => {
    if (title != "") {
      dispatch(
        salonupdatenotesAction({
          title: title,
          id: clientId,
          notesid: noteid,
        })
      ).then(function () {
        settitle("");
        setNotesid("");
        dispatch(fetchClientById(clientDetail._id));
      });
    } else {
      toast.error("Title is required");
    }
  };

  //Add and update medical history
  const updatemedicalhistory = () => {
    dispatch(
      updatemedicalhistoryAction({
        customer_id: clientId,
        customermedicalhistory: customermedicalhistory,
      })
    ).then(function () {
      Setcustomermedicalhistory(customermedicalhistory);
      setShow(false);
      dispatch(fetchClientById(clientDetail._id));
    });
  };
  const updatethestatus = (id, status, notes) => {
    // Find the index of the item
    const index = customermedicalhistory?.findIndex(
      (obj) => obj?.medicalquestion_id?.toString() === id?.toString()
    );

    if (index !== -1) {
      // If the item exists, update its status
      const updatedHistory = customermedicalhistory?.map((item, i) =>
        i === index ? { ...item, status: status, notes } : item
      );
      Setcustomermedicalhistory(updatedHistory);
    } else {
      // If the item does not exist, add a new item
      Setcustomermedicalhistory([
        ...customermedicalhistory,
        { medicalquestion_id: id, status: status, notes },
      ]);
    }
  };

  const handleAddMore = () => {
    setMedications([...medications, { name: "", dosage: "", lengthuses: "" }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newMedications = medications?.map((medication, i) => {
      if (i === index) {
        return { ...medication, [name]: value };
      }
      return medication;
    });
    setMedications(newMedications);
  };

  const handleSaveData = () => {
    // Handle save data logic here
    dispatch(
      updatemedicalhistoryAction({
        customer_id: clientId,
        medications: medications,
        customermedicalhistory: customermedicalhistory,
      })
    ).then(function () {
      Setcustomermedicalhistory(customermedicalhistory);
      setMedications(medications);
      setShow(false);
      dispatch(fetchClientById(clientDetail._id));
    });

    console.log("Saving data...", medications);
  };

  //end medical history
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [file2, setFile2] = useState("");
  function handleChange2(e) {
    console.log(e.target.files);
    Setprofileimage(e.target.files[0]);
    setFile2(URL.createObjectURL(e.target.files[0]));
  }

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [consutationid, setConsultationid] = useState("");
  //update client information

  const formik = useFormik({
    initialValues: {
      first_name: clientDetail?.first_name || "",
      phone_number: "",
      last_name: "",
      email: "",
      pronouns: "",

      first_line_address: "",
      second_line_address: "",
      city: "",

      gender: "6",
      self_describe: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name  is required"),

      last_name: Yup.string().required("Surname   is required"),

      self_describe: Yup.string().when("gender", {
        is: "3", // Condition: Require 'self_describe' if 'gender' is 3
        then: Yup.string().required("Self describe is required."),
        otherwise: Yup.string(), // 'self_describe' is optional otherwise
      }),

      email: Yup.string().email().required("Email   is required"),
      first_line_address: Yup.string().required(
        "One Line  address  is required"
      ),
      phone_number: Yup.string().required("Phone number  is required"),
      city: Yup.string().required("City  is required"),
    }),
    onSubmit: (values) => {
      values.customer_id = clientDetail?._id;
      values.profileimage = profileimage;
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
      setformupdatedata(formData);
      handleShow4();
      handleClose3();
      // dispatch(clientupdateinformationAction(formData)).then(function (data) {
      //   dispatch(fetchClientById(clientDetail._id));
      // });

      // Handle step 1 submission
    },
  });
  useEffect(() => {
    for (let key in clientDetail) {
      if (clientDetail[key] != null) {
        formik.setFieldValue(key, clientDetail[key], { strict: false });
      }
    }
  }, [clientDetail]);
  //clientupdateinformationAction

  const saveinformation = () => {
    dispatch(clientupdateinformationAction(formupdatedata)).then(function (
      data
    ) {
      dispatch(fetchClientById(clientDetail._id));
      handleClose4();
    });
  };

  //delete the notes

  const salondeletenotes = () => {
    dispatch(
      salondeletenotesAction({
        notesid: deletednotesid,
      })
    ).then(function (data) {
      dispatch(fetchClientById(clientDetail._id));
      handleClose5();
      settitle("");
      setDeletednotesid("");
    });
  };

  const navigate = useNavigate();

  //all consultation list
  const optionconsultations = consultationFormData?.data?.map((item) => {
    return {
      label: item?.form_title,
      value: item?._id,
    };
  });

  const startconsultation = () => {
    if (consutationid != "") {
      navigate(
        `/consultation_form/${Encryptedid(consutationid.value)}/${Encryptedid(
          clientId
        )}`
      );
    } else {
      toast.error("Please select client and consultation");
    }
  };
  return (
    <Layout>
      <div className="result_view">
        <section className="product_view">
          <div className="productmain_wrap">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="prev_main">
                      <img
                        src={
                          require("../../src/assets/img/right-arrow.svg")
                            .default
                        }
                        alt="arrow"
                        className="arrow-next-green"
                      />
                      <NavLink className="prev_result" to="/myclient">
                        Back to search results
                      </NavLink>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-end align-items-center">
                    <div className="schedule_top px-0">
                      <Nav.Link as={Link} to="/pre-care">
                        Send Pre/Post Care
                      </Nav.Link>
                    </div>
                    <div className="schedule_top px-0">
                      <Nav.Link
                        as={Link}
                        onClick={() => {
                          setnewconsultationview(true);
                        }}
                      >
                        New Consultation
                      </Nav.Link>
                    </div>
                    {/* <div className="schedule_top px-0">
                      <Nav.Link as={Link} to="/Consultation">
                        Send Consultation
                      </Nav.Link>
                      <Nav.Link onClick={() => setModalShowvideo(true)}>
                        New Consultation
                      </Nav.Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="user_info bod_cmn">
                  <div className="Edit-btn">
                    <button onClick={handleShow3}>Edit</button>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <img
                        src={
                          clientDetail?.profileimage == undefined
                            ? require("../assets/img/logo-circle.png")
                            : process.env.REACT_APP_HOST_NAME +
                              clientDetail?.profileimage
                        }
                        alt="arrow"
                        className="logo-main"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <h2 className="client_cmn_heading">
                        {clientDetail?.first_name +
                          " " +
                          clientDetail?.last_name}
                      </h2>

                      <p>
                        {clientDetail?.first_line_address}
                        <br />
                        {clientDetail?.second_line_address} <br />
                        {clientDetail?.city}
                        <br />
                        {clientDetail?.pin_code}
                      </p>
                      <p>
                        {" "}
                        {(() => {
                          if (clientDetail?.gender == 3) {
                            return clientDetail?.self_describe; // Render self_describe if gender is 3
                          } else if (clientDetail?.gender == 4) {
                            return "Prefer not to say";
                          } else if (clientDetail?.gender == 2) {
                            return "Non Binary";
                          } else if (clientDetail?.gender == 1) {
                            return "Female";
                          } else if (clientDetail?.gender == 0) {
                            return "Male";
                          }
                        })()}{" "}
                        {clientDetail?.pronouns}
                      </p>

                      <p>{clientDetail?.email}</p>
                      <p>+44 {clientDetail?.phone_number}</p>
                      {clientDetail?.dob && (
                        <p>
                          Age: {calculatedAge} (
                          {moment(clientDetail?.dob).format("DD/MM/YYYY")})
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="view-medical mb-4">
                  <button type="button" onClick={handleShow}>
                    View Medical History
                  </button>
                </div>

                <div className="schedule bod_cmn set">
                  <div className="schedule_top">
                    <h2 className="client_cmn_heading">Documents </h2>
                    <div className="buttnm">
                      <Nav.Link onClick={handleShow2}>Add Document</Nav.Link>
                    </div>
                  </div>

                  <div className="docs-add-sec">
                    <div className="row justify-content-center">
                      {clientDetail?.document?.map(function (object) {
                        return (
                          <div className="col-lg-12">
                            <div className="upload-doc-main">
                              <div className="uploaded-doc-box">
                                <h2>{object?.document_title}</h2>

                                <p>
                                  {object?.client_document
                                    .substring(
                                      object?.client_document.lastIndexOf("/") +
                                        1
                                    )
                                    .split(".")
                                    .pop()}
                                </p>
                                <a
                                  href={
                                    process.env.REACT_APP_HOST_NAME +
                                    object?.client_document
                                  }
                                  target="_blank"
                                >
                                  View
                                </a>
                                <p
                                  onClick={() => {
                                    setShow2(true);
                                    setdocument_title(object?.document_title);
                                    setfileexists(1);
                                    setdoucumentid(object?._id);
                                    setclient_document(
                                      process.env.REACT_APP_HOST_NAME +
                                        object?.client_document
                                    );
                                    setPrevImg2(
                                      object?.client_document
                                        .substring(
                                          object?.client_document.lastIndexOf(
                                            "/"
                                          ) + 1
                                        )
                                        .split(".")
                                        .pop()
                                    );
                                  }}
                                >
                                  Edit
                                </p>
                              </div>
                              <button
                                type="button"
                                onClick={() => {
                                  dispatch(
                                    filedeleteAction({
                                      doucumentid: object?._id,
                                    })
                                  ).then(function () {
                                    dispatch(fetchClientById(clientId));
                                  });
                                }}
                              >
                                <img
                                  src={require("../assets/img/closeconsult.png")}
                                />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="appointment set notes">
                  <div className="schedule_top">
                    <h2 className="client_cmn_heading">Notes</h2>
                  </div>
                  <div className="notes-inner">
                    {clientDetail?.notes?.map(function (object) {
                      return (
                        <div className="notes-add">
                          <div className="d-flex justify-content-end align-items-center flex-wrap mb-3 position-relative">
                            <h5>
                              {moment(object?.createdAt).format("DD-MM-YYYY")}
                            </h5>
                            <div className="notes-buttons">
                              <button
                                onClick={() => {
                                  setNotesid(object?._id);
                                  settitle(object.title);
                                }}
                              >
                                <img
                                  src={
                                    require("../assets/img/pencil-edit.svg")
                                      .default
                                  }
                                />
                              </button>
                              <button
                                onClick={() => {
                                  setShow5(true);
                                  setDeletednotesid(object?._id);
                                }}
                              >
                                {" "}
                                <img
                                  src={require("../assets/img/closeconsult.png")}
                                />
                              </button>
                            </div>
                          </div>
                          <p>{object?.title}</p>
                          {noteid == object?._id && (
                            <>
                              <div className="text-field">
                                <textarea
                                  rows={3}
                                  placeholder="Type Here.."
                                  value={title}
                                  name="notes"
                                  onChange={(e) => {
                                    settitle(e.target.value);
                                  }}
                                >
                                  {title}
                                </textarea>
                              </div>
                              <div className="d-flex justify-content-center">
                                <button onClick={addnotes}>Update</button>
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="notes-add-field">
                    {noteid == "" && (
                      <>
                        {" "}
                        <div className="text-field">
                          <textarea
                            rows={3}
                            placeholder="Type Here.."
                            value={title}
                            onChange={(e) => {
                              settitle(e.target.value);
                            }}
                          >
                            {title}
                          </textarea>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button onClick={addnotes}>Add</button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="schedule bod_cmn set activity-tab">
                    <div className="schedule_top">
                      <h2 className="client_cmn_heading">Activity</h2>
                      <div className="buttnm">
                        <Nav.Link onClick={() => setModalShownew(true)}>
                          New Appointment
                        </Nav.Link>
                        {/* <Nav.Link onClick={() => setModalShowvideo(true)}>
                        New Consultation
                      </Nav.Link> */}
                      </div>
                    </div>
                    <h4 className="activity-heading">Upcoming</h4>
                    <div class="table-responsive">
                      <table>
                        {upcomingSchedule?.length > 0 ? (
                          upcomingSchedule?.some(
                            (item) => item?.clientId?._id == clientId
                          ) ? (
                            upcomingSchedule?.map((item, i) => {
                              return (
                                item?.clientId?._id == clientId && (
                                  <tr>
                                    <td>
                                      {moment(item?.date).format("DD/MM/YYYY")}{" "}
                                      {item?.time}
                                    </td>
                                    <td className="text-left description-set">
                                      {item?.description.slice(0, 10)}
                                    </td>
                                    <td>
                                      {item?.appointment_type == 0
                                        ? "Video Consultation"
                                        : "Salon Visit"}
                                    </td>
                                    <td>
                                      {item?.appointment_type == 0 ? (
                                        <Nav.Link
                                          as={Link}
                                          to={`/video_consultation/${Encryptedid(
                                            item?._id
                                          )}/${Encryptedid(
                                            item?.clientId?._id
                                          )}`}
                                        >
                                          View
                                        </Nav.Link>
                                      ) : (
                                        "View"
                                      )}
                                    </td>
                                  </tr>
                                )
                              );
                            })
                          ) : (
                            <p className="no-information mt-4">
                              No information to show
                            </p>
                          )
                        ) : (
                          <p className="no-information mt-4">
                            No information to show
                          </p>
                        )}
                      </table>
                    </div>
                    <h4 className="activity-heading">Past</h4>
                    <div class="table-responsive">
                      <table>
                        {appointmentHistory?.length > 0 ? (
                          appointmentHistory?.some(
                            (item) => item?.clientId?._id == clientId
                          ) ? (
                            appointmentHistory?.map((item, i) => {
                              return (
                                item?.clientId?._id == clientId && (
                                  <tr>
                                    <td>
                                      {moment(item?.date).format("DD/MM/YYYY")}{" "}
                                      {item?.time}
                                    </td>
                                    <td className="text-left">
                                      {item?.description}
                                    </td>
                                    <td>
                                      {item?.appointment_type == 0
                                        ? "Video Consultation"
                                        : "Salon Visit"}
                                    </td>
                                    <td>
                                      {item?.appointment_type == 0 ? (
                                        <Nav.Link
                                          as={Link}
                                          to={`/video_consultation/${Encryptedid(
                                            item?._id
                                          )}/${Encryptedid(
                                            item?.clientId?._id
                                          )}`}
                                        >
                                          View
                                        </Nav.Link>
                                      ) : (
                                        "View"
                                      )}
                                    </td>
                                  </tr>
                                )
                              );
                            })
                          ) : (
                            <p className="no-information mt-4">
                              No information to show
                            </p>
                          )
                        ) : (
                          <p className="no-information mt-4">
                            No information to show
                          </p>
                        )}
                      </table>
                    </div>
                  </div>

                  {/* <div className="appointment bod_cmn set">
                    <div className="schedule_top">
                      <h2 className="client_cmn_heading">
                        Completed Consultations
                      </h2>
                    </div>
                    <div class="table-responsive">
                      <table>
                        {completed_consultation?.length > 0 ? (
                          completed_consultation?.some(
                            (item) => item?.customerId?._id == clientId
                          ) ? (
                            completed_consultation?.map((item, i) => {
                              return (
                                item?.customerId?._id == clientId && (
                                  <tr>
                                    <td>
                                      {moment(item?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}{" "}
                                      {item?.time}
                                    </td>
                                    <td className="text-left">
                                      {item?.consultationId?.form_title}
                                    </td>
                                    <td>Connsultation</td>
                                    <td>
                                      <Nav.Link
                                        as={Link}
                                        to={`/view_consultation_form/${Encryptedid(
                                          item?._id
                                        )}`}
                                      >
                                        View
                                      </Nav.Link>
                                    </td>
                                  </tr>
                                )
                              );
                            })
                          ) : (
                            <p className="no-information">
                              No information to show
                            </p>
                          )
                        ) : (
                          <p className="no-information">
                            No information to show
                          </p>
                        )}
                      </table>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Invoice popup */}
        <InvoiceModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          salonClients={salonClients}
        />

        {/* Schedule new appointment */}
        <AppointmentModal
          show={modalShownew}
          onHide={() => setModalShownew(false)}
          salonClients={salonClients}
          clientId={clientId}
        />

        {/* Schedule video consultation */}
        <ConsultationModal
          show={modalShowvideo}
          onHide={() => setModalShowvideo(false)}
          salonClients={salonClients}
        />

        <Modal
          show={show}
          onHide={handleClose}
          className="view-medical-history"
        >
          <Modal.Header closeButton>
            <Modal.Title>Medical History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-stamp">
              {/* <h3>{moment(clientDetail?.createdAt).format("DD/MM/YYYY")}</h3> */}
              <h4>
                Last Updated:{" "}
                {moment(clientDetail?.updatedAt).format("MMMM D, YY")}
              </h4>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Medical History</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-sec">
                    {questionlist?.map((item, i) => {
                      return (
                        <>
                          <Row className="d-flex align-items-center">
                            <Col lg={9} md={9} sm={9}>
                              <h3>{item?.title}</h3>
                            </Col>
                            <Col lg={3} md={3} sm={3}>
                              <div className="toggle-btns justify-content-end">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name={"flexRadioDefault" + i}
                                    id={"flexRadioDefault1" + i}
                                    checked={
                                      customermedicalhistory?.find(
                                        (obj) =>
                                          obj?.medicalquestion_id?.toString() ===
                                          item?._id?.toString()
                                      )?.status === 1
                                    }
                                    onClick={() => {
                                      updatethestatus(item._id, 1);
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for={"flexRadioDefault1" + i}
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    checked={
                                      customermedicalhistory?.find(
                                        (obj) =>
                                          obj?.medicalquestion_id?.toString() ===
                                          item?._id?.toString()
                                      )?.status === 0
                                    }
                                    name={"flexRadioDefault" + i}
                                    id={"flexRadioDefault1" + i}
                                    onClick={() => {
                                      updatethestatus(item._id, 0);
                                    }}
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </Col>
                            {customermedicalhistory?.find(
                              (obj) =>
                                obj?.medicalquestion_id?.toString() ===
                                item?._id?.toString()
                            )?.status == 0 && (
                              <Col
                                lg={12}
                                className={isHidden ? "d-block" : "d-block"}
                              >
                                <Form>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>
                                      Please provide details
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Type Here.."
                                      value={
                                        customermedicalhistory?.find(
                                          (obj) =>
                                            obj?.medicalquestion_id?.toString() ===
                                            item?._id?.toString()
                                        )?.notes
                                      }
                                      onChange={(e) => {
                                        updatethestatus(
                                          item._id,
                                          0,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                            )}
                          </Row>
                        </>
                      );
                    })}
                    <Row className="d-flex align-items-center">
                      <Col lg={12} className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="save-btn-treat"
                          onClick={updatemedicalhistory}
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Medication</Accordion.Header>
                <Accordion.Body>
                  <div className="accordian-sec">
                    {medications?.map((medication, index) => (
                      <Row key={index} className="d-flex align-items-center">
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Medication Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Write Here.."
                              name="name"
                              value={medication.name}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Dosage</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Optional"
                              name="dosage"
                              value={medication.dosage}
                              onChange={(e) => handleChange(index, e)}
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <Form.Group className="mb-3">
                            <Form.Label>Length of use</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type Here.."
                              value={medication.lengthuses}
                              onChange={(e) => handleChange(index, e)}
                              name="lengthuses"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    ))}
                    <Row className="d-flex align-items-center">
                      <Col lg={12} className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="save-btn-treat"
                          onClick={handleAddMore}
                        >
                          Add more
                        </button>
                        <button
                          type="button"
                          className="save-btn-treat"
                          onClick={handleSaveData}
                        >
                          Save Data
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
        </Modal>

        <Modal show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Add Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="client_consulation">
              {" "}
              <div className="sign-detail-input">
                <label>Document Title</label>
                <input
                  className="form-control"
                  placeholder="Type here"
                  name="document_title"
                  value={document_title}
                  onChange={(e) => {
                    setdocument_title(e.target.value);
                  }}
                />
                <label>Document</label>
                <div className="img-upld-box">
                  {prevImg2 != "pdf" &&
                    client_document == "string" &&
                    !client_document?.includes("pdf") && (
                      <>
                        <img
                          className="upload-img-icon"
                          src={require("../assets/img/uplod-img.svg").default}
                        />
                        <p>Upload Insurance</p>
                      </>
                    )}
                  {prevImg2 == "" && (
                    <img
                      className="place-img"
                      src={require("../assets/img/uploaddocimage.svg").default}
                    />
                  )}

                  <input
                    name="client_document"
                    type="file"
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];

                      if (selectedFile) {
                        if (selectedFile.type === "application/pdf") {
                          setPrevImg2("pdf"); // Clear image preview
                          setPdfFile(selectedFile);
                        } else {
                          setPrevImg2(URL.createObjectURL(selectedFile));
                        }
                      }

                      setfile(selectedFile);
                    }}
                  />

                  {prevImg2 ? (
                    prevImg2 == "pdf" ? (
                      <p className="mt-0">Document Uploaded ✅</p>
                    ) : (
                      <img
                        className="view-img"
                        src={fileexists == 1 ? client_document : prevImg2}
                      />
                    )
                  ) : client_document &&
                    typeof client_document == "string" &&
                    client_document?.includes("pdf") ? (
                    <p className="mt-0">Document Uploaded ✅</p>
                  ) : (
                    client_document && (
                      <img className="view-img" src={client_document} />
                    )
                  )}
                </div>

                {prevImg2 && prevImg2 == "pdf" && (
                  <a
                    className="upload-btn"
                    target="_blank"
                    href={
                      fileexists == 1
                        ? client_document
                        : URL.createObjectURL(file)
                    }
                  >
                    View Document
                  </a>
                )}
              </div>
            </div>
          </Modal.Body>
          <ModalFooter>
            {" "}
            <div className="submit-btn schedule p-0">
              <button
                type="submit"
                class="btn mb-3"
                onClick={uploadnewdocument}
              >
                Save Changes
              </button>
            </div>
            <div className="cancel-btn" onClick={handleClose2}>
              <button type="submit" class="btn mb-3">
                Cancel
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal show={show3} onHide={handleClose3} className="edit-client-pop">
          <Modal.Header closeButton>
            <Modal.Title>Edit Client Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <div className="account_form edit-details">
                <div className="row">
                  <div className="col-lg-6">
                    <label>Profile Picture</label>
                    <div className="profile-upload">
                      <input type="file" onChange={handleChange2} />
                      {(file2 != "" ||
                        clientDetail?.profileimage != undefined) && (
                        <img
                          src={
                            file2 == ""
                              ? process.env.REACT_APP_HOST_NAME +
                                clientDetail?.profileimage
                              : file2
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>First Name *</label>

                    <input
                      className="form-control"
                      placeholder="First Name"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.first_name && formik.errors.first_name && (
                      <span className="error">{formik.errors.first_name}</span>
                    )}

                    <label>Surname *</label>
                    <input
                      className="form-control"
                      placeholder="Surname"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.last_name &&
                      formik.errors.last_name && (
                        <span className="error">{formik.errors.last_name}</span>
                      )}
                  </div>
                  <div className="col-lg-6">
                    <label>Gender</label>

                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="6" selected>
                        Select
                      </option>
                      <option value="0">Male</option>
                      <option value="1">Female</option>
                      <option value="2">Non Binary</option>
                      <option value="3">Others </option>
                      <option value="4">Prefer not to say </option>
                    </select>
                    {formik.submitCount > 0 &&
                      formik.touched.gender &&
                      formik.errors.gender && (
                        <span className="error">{formik.errors.gender}</span>
                      )}
                  </div>
                  {formik.values.gender == 3 && (
                    <div className="col-lg-6">
                      <label>Self-Describe </label>
                      <input
                        className="form-control"
                        placeholder=""
                        name="self_describe"
                        value={formik.values.self_describe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.submitCount > 0 &&
                        formik.touched.self_describe &&
                        formik.errors.self_describe && (
                          <span className="error">
                            {formik.errors.self_describe}
                          </span>
                        )}
                    </div>
                  )}
                  <div className="col-lg-6">
                    <label>Pronouns</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      name="pronouns"
                      value={formik.values.pronouns}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option selected>Select</option>
                      <option value="She/Her">She/Her</option>
                      <option value="He/Him">He / Him</option>
                      <option value="They/Them">They / Them</option>
                      <option value="Others">Others </option>
                    </select>

                    {formik.submitCount > 0 &&
                      formik.touched.pronouns &&
                      formik.errors.pronouns && (
                        <span className="error">{formik.errors.pronouns}</span>
                      )}
                  </div>
                  {/* <div className="col-lg-6">
                        <label>DOB</label>
                        <DateOfBirthPicker dob={dob} setDOB={setDOB} />
                      </div> */}
                  <div className="col-lg-6">
                    <label>Email Address *</label>
                    <input
                      className="form-control"
                      placeholder="Type to search"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.email &&
                      formik.errors.email && (
                        <span className="error">{formik.errors.email}</span>
                      )}
                  </div>
                  <div className="col-lg-6">
                    <label>Phone *</label>
                    <input
                      className="form-control"
                      placeholder="Type to search"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.phone_number &&
                      formik.errors.phone_number && (
                        <span className="error">
                          {formik.errors.phone_number}
                        </span>
                      )}
                  </div>
                  <div className="col-lg-12">
                    <select
                      name="address"
                      class={`form-select ${
                        formik.values.address ? "" : "selected_color"
                      }`}
                      aria-label="Default select example"
                      onChange={(e) => {
                        formik.handleChange(e);
                        const data = addressData?.addresses?.find(
                          (element) =>
                            element?.formatted_address?.toString() ==
                            e.target.value?.toString()
                        );

                        formik.setFieldValue(
                          "first_line_address",
                          data?.line_1
                        );
                        formik.setFieldValue(
                          "second_line_address",
                          data?.town_or_city
                        );
                        formik.setFieldValue("city", data?.line_2);
                      }}
                      value={formik.values.address}
                    >
                      <option>Confirm Address</option>
                      {addressData?.addresses?.map((item) => {
                        return (
                          <option
                            value={item?.formatted_address
                              ?.filter((item) => item !== "")
                              .join(",")}
                          >
                            {item?.formatted_address
                              ?.filter((item) => item !== "")
                              .join(",")}
                          </option>
                        );
                      })}
                    </select>
                    {formik.submitCount > 0 && formik.errors.address && (
                      <span>{formik.errors.address}</span>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label>Address</label>
                    <input
                      className="form-control mb-2"
                      placeholder="First Line "
                      name="first_line_address"
                      value={formik.values.first_line_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.first_line_address &&
                      formik.errors.first_line_address && (
                        <span className="error">
                          {formik.errors.first_line_address}
                        </span>
                      )}
                    <input
                      className="form-control mb-2"
                      placeholder="Second Line "
                      name="second_line_address"
                      value={formik.values.second_line_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.second_line_address &&
                      formik.errors.second_line_address && (
                        <span className="error">
                          {formik.errors.second_line_address}
                        </span>
                      )}
                    <input
                      className="form-control"
                      placeholder="City"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.submitCount > 0 &&
                      formik.touched.city &&
                      formik.errors.city && (
                        <span className="error">{formik.errors.city}</span>
                      )}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <div className="row">
                      <div className="col-lg-6 ">
                        <label>Post Code Search</label>
                        <input
                          className="form-control"
                          placeholder="Post Code"
                          name="pin_code"
                          value={formik.values.pin_code}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div className="col-lg-6 d-flex align-items-end">
                        <button
                          type="button"
                          onClick={() => {
                            getAddress(formik.values.pin_code);
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>{" "}
                  <div className="submit-btn">
                    <button class="btn mb-3" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={show4} onHide={handleClose4} className="">
          <Modal.Header closeButton style={{ border: "0" }}></Modal.Header>
          <Modal.Body>
            <div className="permission-popup">
              <p>
                {" "}
                Do you have permission from the customer to update these
                details?
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer
            className="d-flex justify-content-center"
            style={{ border: "0" }}
          >
            <div className="submit-btn schedule p-0">
              <button type="submit" class="btn mb-3" onClick={saveinformation}>
                Save Changes
              </button>
            </div>
            <div className="cancel-btn" onClick={handleClose4}>
              <button type="submit" class="btn mb-3">
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal show={show5} onHide={handleClose5} className="">
          <Modal.Header closeButton style={{ border: "0" }}></Modal.Header>
          <Modal.Body>
            <div className="permission-popup text-center">
              <p> Are You Sure to Delete This Note</p>
            </div>
          </Modal.Body>
          <Modal.Footer
            className="d-flex justify-content-center"
            style={{ border: "0" }}
          >
            <div className="submit-btn schedule p-0">
              <button type="submit" class="btn mb-3" onClick={salondeletenotes}>
                Delete
              </button>
            </div>
            <div className="cancel-btn" onClick={handleClose5}>
              <button type="submit" class="btn mb-3">
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={newconsultationview}
          onHide={() => {
            setnewconsultationview(false);
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="client-consult precare"
        >
          <Modal.Body closeButton>
            <div className="client_consulation">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Search for Consultation</label>
                    <Select
                      name="customerId"
                      options={optionconsultations}
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => setConsultationid(e)}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <Row>
                    <Col lg={12} xs={12}>
                      <div className="submit-btn">
                        <button
                          type="submit"
                          class="btn mb-3"
                          onClick={() => {
                            startconsultation();
                          }}
                        >
                          Start
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};
export default ClientView;
