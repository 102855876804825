import { useEffect } from 'react';
import RoutesPage from './Routes/Routes';
import toast, { Toaster } from 'react-hot-toast';
import ScrollToTop from './components/ScrollToTop';
import { useDispatch } from 'react-redux';
import { getSubscriptionById } from './Redux/Actions/user/salon';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
  
    dispatch(getSubscriptionById(localStorage.getItem("userid")));
  }, [])

  return (
    <ScrollToTop>
      <div className="App">
        <Toaster position="top-right"
          reverseOrder={false} />
        <RoutesPage />
      </div>
    </ScrollToTop>
  );
}

export default App;

