import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getSalonTabsStatics } from "../Redux/Actions/user/salon";
import Dropdown from "react-bootstrap/Dropdown";
export default function DashboardOption() {
  const dispatch = useDispatch();
  const decoded = jwtDecode(localStorage.getItem("token"));
  const subscrtiption_data = useSelector(
    (state) => state?.myaccount?.subscription_data
  );

  const salonTabData = useSelector(
    (state) => state?.myaccount?.salonTabStatics
  );
  const [toggle, SetToggle] = useState(false);

  useEffect(() => {
    dispatch(getSalonTabsStatics());
  }, []);

  console.log(salonTabData, "salonTabData");

  return (
    <div>
      <div
        className={
          !toggle
            ? "bg-color dashboard-option"
            : "bg-color dashboard-option two"
        }
      >
        <div className="container">
          <div className="nav-dash">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="logo-dash">
                  <img
                    src={require("../assets/img/Consultifygolden.svg").default}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex justify-content-end align-items-end">
                <div className="acc-button">
                  {/* {!toggle ? (
                    <Link to="/myaccount" className="in-btn">
                      My Account
                    </Link>
                  ) : ( */}
                  <p>
                    Welcome Back,{" "}
                    {(decoded?.data?.firstname || "") +
                      " " +
                      (decoded?.data?.lastname || "")}
                    !
                  </p>
                  {/* )} */}
                </div>
                <div className="nav-menu-new">
                  <Link to="/schedule" className="head-link">
                    {" "}
                    <img src={require("../assets/img/calendor.svg").default} />
                  </Link>{" "}
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={require("../assets/img/Menu.svg").default} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/consultation">
                        <div className="menu-item-inner">
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="33"
                              viewBox="0 0 32 33"
                              fill="none"
                            >
                              <path
                                d="M16.0942 16.3236C16.0942 7.54098 9.35931 0.421233 1.05139 0.421233V32.226C9.35931 32.226 16.0942 25.1063 16.0942 16.3236Z"
                                fill="#427272"
                              />
                              <path
                                d="M16.0942 16.3236C16.0942 25.1063 22.8291 32.226 31.137 32.226V0.421233C22.8291 0.421233 16.0942 7.54098 16.0942 16.3236Z"
                                fill="#427272"
                              />
                            </svg>
                            <div>
                              <h3>Consultations</h3>
                              <p>Send and Manage Consultations</p>
                            </div>
                          </div>
                          <img
                            src={require("../assets/img/arrow.svg").default}
                          />
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/myclient">
                        <div className="menu-item-inner">
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                            >
                              <path
                                d="M0.191772 0.0684967H16.0942C16.0942 8.85115 8.97442 15.9709 0.191772 15.9709V0.0684967Z"
                                fill="#427272"
                              />
                              <path
                                d="M0.191772 31.8733H16.0942C16.0942 23.0906 8.97442 15.9709 0.191772 15.9709V31.8733Z"
                                fill="#427272"
                              />
                              <path
                                d="M31.9966 0.0684967H16.0942C16.0942 8.85115 23.2139 15.9709 31.9966 15.9709V0.0684967Z"
                                fill="#427272"
                              />
                              <path
                                d="M31.9966 31.8733H16.0942C16.0942 23.0906 23.2139 15.9709 31.9966 15.9709V31.8733Z"
                                fill="#427272"
                              />
                            </svg>
                            <div>
                              <h3>My Clients</h3>
                              <p>Manage Clients</p>
                            </div>
                          </div>
                          <img
                            src={require("../assets/img/arrow.svg").default}
                          />
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/forumhome">
                        <div className="menu-item-inner">
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="33"
                              viewBox="0 0 32 33"
                              fill="none"
                            >
                              <path
                                d="M17.8611 0.715759H14.3272V12.3524L6.09889 4.12406L3.60007 6.62288L11.8284 14.8512H0.191772V18.3851H11.8284L3.60007 26.6134L6.09889 29.1123L14.3272 20.8839V32.5206H17.8611V20.8839L26.0895 29.1123L28.5883 26.6134L20.3599 18.3851H31.9966V14.8512H20.3599L28.5883 6.62287L26.0895 4.12405L17.8611 12.3524V0.715759Z"
                                fill="#427272"
                              />
                            </svg>
                            <div>
                              <h3>Forum</h3>
                              <p>Topics and Discussions</p>
                            </div>
                          </div>
                          <img
                            src={require("../assets/img/arrow.svg").default}
                          />
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item as={Link} to="/pre-care">
                        <div className="menu-item-inner">
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="33"
                              viewBox="0 0 32 33"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M16.0942 0.363022C7.31152 0.363022 0.191772 7.48277 0.191772 16.2654C0.191772 25.0481 7.31152 32.1678 16.0942 32.1678C24.8768 32.1678 31.9966 25.0481 31.9966 16.2654C31.9966 7.48277 24.8768 0.363022 16.0942 0.363022ZM16.0942 26.867C10.2391 26.867 5.49257 22.1205 5.49257 16.2654L26.6958 16.2654C26.6958 22.1205 21.9493 26.867 16.0942 26.867Z"
                                fill="#427272"
                              />
                            </svg>
                            <div>
                              <h3>Pre & After Care</h3>
                              <p>Advice for Clients</p>
                            </div>
                          </div>
                          <img
                            src={require("../assets/img/arrow.svg").default}
                          />
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/myaccount">
                        <div className="menu-item-inner">
                          <div className="d-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="33"
                              viewBox="0 0 32 33"
                              fill="none"
                            >
                              <path
                                d="M16.0942 16.3236C16.0942 7.54098 9.35931 0.421233 1.05139 0.421233V32.226C9.35931 32.226 16.0942 25.1063 16.0942 16.3236Z"
                                fill="#427272"
                              />
                              <path
                                d="M16.0942 16.3236C16.0942 25.1063 22.8291 32.226 31.137 32.226V0.421233C22.8291 0.421233 16.0942 7.54098 16.0942 16.3236Z"
                                fill="#427272"
                              />
                            </svg>
                            <div>
                              <h3>Settings</h3>
                              <p>Manage your Profile & Settings</p>
                            </div>
                          </div>
                          <img
                            src={require("../assets/img/arrow.svg").default}
                          />
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="dash-options">
            <div className="tabs-two-main">
              <div className="side-options-main">
                {salonTabData?.salonTabArr?.map((item, i) => {
                  return (
                    <>
                      <div className="side-options">
                        <>
                          <Link
                            className={
                              item?.isLocked &&
                              subscrtiption_data?.subscriptionType != 2 &&
                              "cursor-set"
                            }
                            to={
                              item?.isLocked &&
                              subscrtiption_data?.subscriptionType == 2
                                ? item?.route
                                : item?.isLocked
                                ? "#"
                                : item?.route
                            }
                          >
                            <img
                              src={
                                process.env.REACT_APP_HOST_NAME + item?.image
                              }
                            />
                            <p>{item?.name} </p>
                            {item?.isLocked == 1 &&
                              subscrtiption_data?.subscriptionType != 2 && (
                                <button type="button" className="lock-btn">
                                  <img
                                    src={
                                      require("../assets/img/lock.svg").default
                                    }
                                  />
                                </button>
                              )}
                            {/* {item?.isLocked == 1 &&
                                subscrtiption_data?.subscriptionType == 2 && (
                                  <button type="button" className="lock-btn">
                                    <img
                                      src={
                                        require("../assets/img/unlock.svg")
                                          .default
                                      }
                                    />
                                  </button>
                                )} */}
                          </Link>
                        </>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
