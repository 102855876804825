import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AddHelpAndSupportAction, changePass } from "../Redux/Actions/user/salon";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import MyAcoountLayout from "../components/Layout/MyAcoountLayout";

const HelpAndSupport = () => {
  const dispatch=useDispatch();
  //AddHelpAndSupportAction
  const formik=useFormik({
    initialValues:{
      title:"",
      description:""
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
   
    }),
    onSubmit:(values)=>{
      dispatch(AddHelpAndSupportAction(values))
      formik.handleReset()
    }
  })
  return (
    <MyAcoountLayout DidYouKnow={true}>
      <div className="col-lg-8 mx-auto">
      <form onSubmit={formik.handleSubmit}>

   
        <div className="account_form pass_security">
          <div>
            <h2>Help & Support</h2>
            <div className="row">
              <div className="col-lg-12">
                <label>Issue Type</label>
                <br></br>
              
                <select   name="title"
                 
                  placeholder="Type Here"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}>
                    <option value="">Please select Issue Type</option>
                  <option value="Issue with my account">Issue with my account</option>
                  <option value="Payments and Subscription">Payments and Subscription</option>
                  <option value="Technical Issue"> Technical Issue</option>
                  <option value="General"> General</option>
                </select>
                {
                 formik.submitCount> 0 && formik.errors.title &&(<div className="error_valid">{ formik.errors.title}</div>)
                }
              </div>
              <div className="col-lg-12">
                <label>Description</label>
                <textarea
                  rows={3}
                  type="text"
                  className="form-control"
                  name="description"
                  placeholder="Type Here"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                   { formik.submitCount> 0 &&
                  formik.errors.description &&(<div className="error_valid">{ formik.errors.description}</div>)
                }
              </div>
            </div>
          </div>
          <div className="submit-btn">
            <button type="submit" class="btn mb-3">
              Send
            </button>
          </div>
        </div>
        </form>
      </div>
    </MyAcoountLayout>
  );
};
export default HelpAndSupport;
