import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateOfBirthPicker = ({dob,setDOB}) => {
  

  return (
    <div>
      <DatePicker
        selected={dob}
        onChange={(date) => setDOB(date)}
        showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        maxDate={new Date()}
        placeholderText="Select DOB"
        dateFormat="dd/MM/yyyy"
      />

    </div>
  );
};

export default DateOfBirthPicker;
