import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createClientAction } from "../Redux/Actions/user/salon";
import DateOfBirthPicker from "../components/DateOfBirthPicker";
import useGetAddressIO from "../Hooks/getAddressIo";

const CreateClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dob, setDOB] = useState();
  const [postcode, setPostcode] = useState();
  const { addressData, getAddress } = useGetAddressIO();
  const [prevImg2, setPrevImg2] = useState();
  const [pdfFile, setPdfFile] = useState();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      phone_number: "",
      last_name: "",
      email: "",
      pronouns: "",

      first_line_address: "",
      second_line_address: "",
      city: "",
      pin_code: "",
      dob: "",
      gender: "",
      self_describe: "",
      document_title: "",
      client_document: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name  is required"),
      pronouns: Yup.string(),
      last_name: Yup.string().required("Surname   is required"),
      gender: Yup.string(),
      self_describe: Yup.string().when("gender", {
        is: "3", // Condition: Require 'self_describe' if 'gender' is 3
        then: Yup.string().required("Self describe is required."),
        otherwise: Yup.string(), // 'self_describe' is optional otherwise
      }),

      email: Yup.string().email().required("Email   is required"),
      first_line_address: Yup.string().required(
        "One Line  address  is required"
      ),
      phone_number: Yup.string().required("Phone number  is required"),
      city: Yup.string().required("City  is required"),
      pin_code: Yup.string().required("Post code  is required"),
    }),
    onSubmit: (values) => {
      values.dob = dob;
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }

      dispatch(createClientAction(formData, navigate)).then(function (data) {
        if (data?.payload) {
          navigate("/myclient");
        }
      });

      // Handle step 1 submission
    },
  });

  console.log(addressData, postcode, "fdddddddddddddddds");

  return (
    <Layout>
      <div className="mydetails">
        <div className="myaccount_tabcmn">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="prev_main">
                  <img
                    src={
                      require("../../src/assets/img/right-arrow.svg").default
                    }
                    alt="arrow"
                    className="arrow-next-green"
                  />
                  <NavLink className="prev_result" to="/myclient">
                    Back to All Clients
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tabs_content">
          <div className="container">
            <div className="row justify-content-center">
              {/* <div className="col-lg-5">
                            <div className="you_know">
                                <h2>Did you know?</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac dolor viverra, varius massa sit ame. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ac dolor viverra, varius massa sit ame. </p>
                            </div>
                        </div> */}
              <div className="col-lg-8">
                <div className="account_form">
                  <form onSubmit={formik.handleSubmit}>
                    <h2>Client Details</h2>
                    <p>
                      Send your consultation for your client to complete
                      themselves.
                    </p>
                    <div className="row">
                      <div className="col-lg-6">
                        <label>First Name *</label>

                        <input
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.first_name &&
                          formik.errors.first_name && (
                            <span className="error">
                              {formik.errors.first_name}
                            </span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <label>Surname *</label>
                        <input
                          className="form-control"
                          placeholder="Surname"
                          name="last_name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.last_name &&
                          formik.errors.last_name && (
                            <span className="error">
                              {formik.errors.last_name}
                            </span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <label>Gender</label>

                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="gender"
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option selected>Select</option>
                          <option value="0">Male</option>
                          <option value="1">Female</option>
                          <option value="2">Non Binary</option>
                          <option value="3">Others </option>
                          <option value="4">Prefer not to say </option>
                        </select>
                        {formik.submitCount > 0 &&
                          formik.touched.gender &&
                          formik.errors.gender && (
                            <span className="error">
                              {formik.errors.gender}
                            </span>
                          )}
                      </div>
                      {formik.values.gender == 3 && (
                        <div className="col-lg-6">
                          <label>Self-Describe </label>
                          <input
                            className="form-control"
                            placeholder=""
                            name="self_describe"
                            value={formik.values.self_describe}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.submitCount > 0 &&
                            formik.touched.self_describe &&
                            formik.errors.self_describe && (
                              <span className="error">
                                {formik.errors.self_describe}
                              </span>
                            )}
                        </div>
                      )}
                      <div className="col-lg-6">
                        <label>Pronouns</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          name="pronouns"
                          value={formik.values.pronouns}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option selected>Select</option>
                          <option value="She/Her">She/Her</option>
                          <option value="He/Him">He / Him</option>
                          <option value="They/Them">They / Them</option>
                          <option value="Others">Others </option>
                        </select>

                        {formik.submitCount > 0 &&
                          formik.touched.pronouns &&
                          formik.errors.pronouns && (
                            <span className="error">
                              {formik.errors.pronouns}
                            </span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <label>DOB</label>
                        <DateOfBirthPicker dob={dob} setDOB={setDOB} />
                      </div>
                      <div className="col-lg-6">
                        <label>Email Address *</label>
                        <input
                          className="form-control"
                          placeholder="Type to search"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.email &&
                          formik.errors.email && (
                            <span className="error">{formik.errors.email}</span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        <label>Phone *</label>
                        <input
                          className="form-control"
                          placeholder="Type to search"
                          name="phone_number"
                          value={formik.values.phone_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.phone_number &&
                          formik.errors.phone_number && (
                            <span className="error">
                              {formik.errors.phone_number}
                            </span>
                          )}
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        <div className="row">
                          <div className="col-lg-6">
                            <label>Post Code Search</label>
                            <input
                              className="form-control"
                              placeholder="Post Code"
                              name="pin_code"
                              value={formik.values.pin_code}
                              onChange={(e) => {
                               
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                            />
                            {formik.submitCount > 0 &&
                              formik.touched.pin_code &&
                              formik.errors.pin_code && (
                                <span className="error">
                                  {formik.errors.pin_code}
                                </span>
                              )}
                          </div>
                          <div className="col-lg-6 d-flex align-items-end">
                            <button type="button" onClick={()=>{
                               getAddress(formik.values.pin_code);
                            }}>Search</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <select
                          name="address"
                          class={`form-select ${
                            formik.values.address ? "" : "selected_color"
                          }`}
                          aria-label="Default select example"
                          onChange={(e) => {
                            formik.handleChange(e);
                            const data = addressData?.addresses?.find(
                              (element) =>
                                element?.formatted_address?.toString() ==
                                e.target.value?.toString()
                            );

                            formik.setFieldValue(
                              "first_line_address",
                              data?.line_1
                            );
                            formik.setFieldValue(
                              "second_line_address",
                              data?.town_or_city
                            );
                            formik.setFieldValue("city", data?.line_2);
                          }}
                          value={formik.values.address}
                        >
                          <option>Confirm Address</option>
                          {addressData?.addresses?.map((item) => {
                            return (
                              <option
                                value={item?.formatted_address
                                  ?.filter((item) => item !== "")
                                  .join(",")}
                              >
                                {item?.formatted_address
                                  ?.filter((item) => item !== "")
                                  .join(",")}
                              </option>
                            );
                          })}
                        </select>
                        {formik.submitCount > 0 && formik.errors.address && (
                          <span>{formik.errors.address}</span>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <label>Address</label>
                        <input
                          className="form-control mb-2"
                          placeholder="First Line "
                          name="first_line_address"
                          value={formik.values.first_line_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.first_line_address &&
                          formik.errors.first_line_address && (
                            <span className="error">
                              {formik.errors.first_line_address}
                            </span>
                          )}
                        <input
                          className="form-control mb-2"
                          placeholder="Second Line "
                          name="second_line_address"
                          value={formik.values.second_line_address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.second_line_address &&
                          formik.errors.second_line_address && (
                            <span className="error">
                              {formik.errors.second_line_address}
                            </span>
                          )}
                        <input
                          className="form-control"
                          placeholder="City"
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.submitCount > 0 &&
                          formik.touched.city &&
                          formik.errors.city && (
                            <span className="error">{formik.errors.city}</span>
                          )}
                      </div>
                     
                      <div className="col-lg-6"></div>{" "}
                      <div className="submit-btn">
                        <button class="btn mb-3" type="submit">
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default CreateClient;
