import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import MyAcoountLayout from "../components/Layout/MyAcoountLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileById,
  getSubscriptionById,
  updateProfile,
} from "../Redux/Actions/user/salon";
import { editProfile } from "../Redux/Actions/user/auth";
import { toast } from "react-hot-toast";

const MySubscription = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state?.myaccount?.userInfo);
  const subscrtiption_data = useSelector(
    (state) => state?.myaccount?.subscription_data
  );

  const submitSubscription = (value) => {
    dispatch(updateProfile({ subscription: value })).then((data) => {
      if (data?.payload?.success) {
        //-> toast.success(data?.payload?.message)
      }
    });
  };

  useEffect(() => {
    dispatch(getProfileById());
    dispatch(getSubscriptionById(localStorage.getItem("userid")));
  }, []);

  const handlePayment = async (price, type) => {
    // Replace 'your_price_id' with the actual Stripe price ID for the selected subscription tier
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/other/createSubscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: localStorage.getItem("userId"),
          priceId: price,
          subscriptionType: type,
        }),
      }
    );

    const result = await response.json();

    if (result?.session?.url) {
      window.location.href = result?.session?.url;
      //submitSubscription(1)
    } else {
      toast.error(result?.msg);
    }

    console.log(result, "handlePayment");
  };

  console.log(subscrtiption_data, "userProfile");

  return (
    <MyAcoountLayout DidYouKnow={true}>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div
            className={`subscription-content ${
              subscrtiption_data?.subscriptionType == 1 && "highlight_border"
            } standard-plan`}
          >
            <div className="subscription-type">
              <div className="subscription-img">
                <img
                  src={require("../assets/img/subscription-img.svg").default}
                  alt="img"
                />
              </div>
              <div className="plan-type-name">
                <h3>Standard</h3>
                <h3>£10.99 per month</h3>
              </div>
            </div>
            <ul className="plan-features">
              <li>
                <img
                  src={require("../assets/img/drk-tick.svg").default}
                  alt="img"
                />
                <h3>Unlimited searches</h3>
              </li>
              <li>
                <img
                  src={require("../assets/img/drk-tick.svg").default}
                  alt="img"
                />
                <h3>Access to our entire database</h3>
              </li>
              <li>
                <img
                  src={require("../assets/img/drk-tick.svg").default}
                  alt="img"
                />
                <h3>Up to 3 user logins</h3>
              </li>
              <li>
                <img
                  src={require("../assets/img/drk-tick.svg").default}
                  alt="img"
                />
                <h3>Upgrade anytime</h3>
              </li>
            </ul>
            <div className="submit-btn">
              <div
                onClick={() => {
                  handlePayment("price_1P4LWmFbPDSMnk9tcO9QJizd", 1);
                }}
                type="submit"
                className="lg-btn"
              >
                {userProfile?.subscriptionType == 1
                  ? "Your Current Plan"
                  : "Manage Subscription Plan"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-lg-4">
        <div
          className={`subscription-content ${
            subscrtiption_data?.subscriptionType == 2 && "highlight_border"
          } `}
        >
          <div className="badge">
            <p>Most popular</p>
          </div>
          <div className="subscription-type">
            <div className="subscription-img">
              <img
                src={require("../assets/img/subscription-img.svg").default}
                alt="img"
              />
            </div>
            <div className="plan-type-name">
              <h3>Pro</h3>
              <h3>£19.99 per month</h3>
            </div>
          </div>
          <ul className="plan-features">
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Unlimited searches</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Access to our entire database</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Unlimited User Logins</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Full Consultation Access</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Create Custom Consultations</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Manage Clients </h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Schedule Appointments</h3>
            </li>
            <li>
              <img
                src={require("../assets/img/drk-tick.svg").default}
                alt="img"
              />
              <h3>Host Video Consultations</h3>
            </li>
          </ul>
          <div className="submit-btn">
            <div
              onClick={() => {
                handlePayment("price_1OedvoFbPDSMnk9txAiItNy2", 2);
              }}
              type="submit"
              className="lg-btn"
            >
              {userProfile?.subscriptionType == 2
                ? "Your Current Plan"
                : "Upgrade Plan"}
            </div>
          </div>
        </div>
      </div> */}
    </MyAcoountLayout>
  );
};
export default MySubscription;
