import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import MyAcoountLayout from "../components/Layout/MyAcoountLayout";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { CustomemaillistAction } from "../Redux/Actions/user/salon";
import { useState } from "react";
export default function CustomEmail() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(
      CustomemaillistAction({
        search,
      })
    );
  }, [search]);
  return (
    <MyAcoountLayout>
      <section className="cmn-sec-headings">
        <Row>
          <Col lg={6}>
            <div className="cmn-sec-left">
              <h2>Automated Email Presets</h2>
              <p>
                Here you can edit some content of the automated emails to be
                sent to your customers.Emails are generically sent to your
                clients, so please do not use names in your email.
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <div className="consultation-presents-tabs">
          <div className="d-flex">
            <button type="button" className="active-tab">
              View All <span>(5)</span>
            </button>
          </div>
          <div className="search-input w-50">
            <InputGroup className="">
              <InputGroup.Text className="group-box-search">
                <img
                  src={require("../assets/img/search.svg").default}
                  alt="search"
                />
              </InputGroup.Text>
              <Form.Control
                type="search"
                className="form-control"
                name="search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search Here"
              />
            </InputGroup>
          </div>
        </div>
      </section>

      <Row>
        <Col lg={12}>
          <div className="d-flex justify-content-end add-tick"></div>
        </Col>
      </Row>
      <section className="search-list-part presents">
        <div className="search-list-box">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={9} sm={9}>
              <div className="search-content-left">
                <h2>New Customer Welcome</h2>
                <div className="sub-heading">New clients to your salon</div>
                <p>Welcoming new clients to your salon</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              sm={3}
              className="d-flex justify-content-center"
            >
              <Link to="/edit-email">View / Edit</Link>
            </Col>
          </Row>
        </div>

        <div className="search-list-box">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={9} sm={9}>
              <div className="search-content-left">
                <h2>New Consultation</h2>
                <div className="sub-heading">
                  New Consultation to your salon
                </div>
                <p>Welcoming new consultation to your salon</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              sm={3}
              className="d-flex justify-content-center"
            >
              <Link to="/edit-new-consultation-mail">View / Edit</Link>
            </Col>
          </Row>
        </div>

        <div className="search-list-box">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={9} sm={9}>
              <div className="search-content-left">
                <h2>New Pre-Care Advice</h2>
                <div className="sub-heading">
                  New Pre-Care Advice to your salon
                </div>
                <p>New Pre-Care Advice to your salon</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              sm={3}
              className="d-flex justify-content-center"
            >
              <Link to="/edit-Precare-advice-mail">View / Edit</Link>
            </Col>
          </Row>
        </div>

        <div className="search-list-box">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={9} sm={9}>
              <div className="search-content-left">
                <h2>New Appointment Created</h2>
                <div className="sub-heading">New Appointment Created</div>
                <p>New Appointment Created to your salon</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              sm={3}
              className="d-flex justify-content-center"
            >
              <Link to="/edit-appointment-created-mail">View / Edit</Link>
            </Col>
          </Row>
        </div>

        <div className="search-list-box">
          <Row className="d-flex align-items-center">
            <Col xs={12} md={9} sm={9}>
              <div className="search-content-left">
                <h2>New Video Call Appointment Created</h2>
                <div className="sub-heading">
                  New Video Call Appointment Created
                </div>
                <p>New Video Call Appointment Created to your salon</p>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              sm={3}
              className="d-flex justify-content-center"
            >
              <Link to="/edit-video-appointment-mail">View / Edit</Link>
            </Col>
          </Row>
        </div>
      </section>
    </MyAcoountLayout>
  );
}
