import { Formik } from "formik";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  createConsultationForm,
  fetchAllCustomers,
  registerStaff,
  salonfetchClientsAction,
} from "../../Redux/Actions/user/salon";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Encryptedid } from "../../utils/BcruptEncyptid";
import Select from "react-select";
import { Col, Row } from "react-bootstrap";

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate();
  const allCustomers = useSelector((state) => state.myaccount.salonClients);

  const [validateValue, setValidateValue] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCustomers());
    dispatch(salonfetchClientsAction());
  }, []);

  const options = allCustomers?.map((item) => {
    return {
      label: item?.first_name + " " + item?.last_name,
      value: item?._id,
    };
  });

  const handleSelectChange = (data, setFieldValue) => {
    setFieldValue("customerId", data?.value);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="client-consult"
    >
      <Modal.Body closeButton>
        <div className="client_consulation">
          <div className="container">
            <Formik
              initialValues={{
                customerId: "",
                msg_for_client: "",
                invoice_amount: "",
                tax_amount: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.customerId) {
                  errors.customerId = "Required*";
                }

                // if (props.modalType != "start") {
                //     if (!values.invoice_amount) {
                //         errors.invoice_amount = "Required*";
                //     }
                //     if (!values.tax_amount) {
                //         errors.tax_amount = "Required*";
                //     }
                // }

                return errors;
              }}
              validateOnChange={validateValue}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                console.log(values, props, "valuse");
                setValidateValue(true);
                if (props.modalType == "start") {
                  navigate(
                    `/consultation_form/${Encryptedid(
                      props.cunsultationId
                    )}/${Encryptedid(values?.customerId)}`
                  );
                } else {
                  dispatch(
                    createConsultationForm({
                      salonId: localStorage.getItem("userId"),
                      customerId: values?.customerId,
                      consultationId: props.cunsultationId,
                      formCompletedBy: "customer",
                    })
                  ).then((data) => {
                    if (data?.payload?.success) {
                      resetForm();
                      toast.success(
                        "Consultation sent to client successfully."
                      );
                      navigate("/consultation");
                    }
                  });
                }
                setSubmitting(false);
                props.setModalShow(false);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <h2>
                    {props.modalType == "start"
                      ? "Prepare consultation for customer"
                      : "Send Consultation to Client"}
                  </h2>
                  <p>
                    Send your consultation for your client to complete
                    themselves.
                  </p>
                  <div className="row">
                    {props.modalType == "start" ? (
                      <>
                        <div className="col-lg-12">
                          <div className="col-lg-12">
                            <Select
                              name="customerId"
                              options={options}
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleSelectChange(e, setFieldValue)
                              }
                            />

                            {errors.customerId && (
                              <span className="error_valid">
                                {errors.customerId}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="submit-btn">
                              <button type="submit" class="btn mb-3">
                                Start
                              </button>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="cancel-btn">
                              <button
                                type="button"
                                onClick={() => props.setModalShow(false)}
                                class="btn mb-3"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-lg-12">
                          <div className="col-lg-12">
                            <label>Select client</label>
                            <Select
                              name="customerId"
                              options={options}
                              class="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleSelectChange(e, setFieldValue)
                              }
                            />
                            {/* <select
                                                                class="form-select"
                                                                aria-label="Default select example"
                                                                onChange={handleChange}
                                                                value={values.customerId}
                                                                name="customerId"
                                                            >
                                                                <option selected>Select Client</option>
                                                                {
                                                                    allCustomers?.map((item, i) => {
                                                                        return (
                                                                            <option value={item?._id}>{item?.first_name + " " + item?.last_name}</option>
                                                                        )
                                                                    })
                                                                }

                                                            </select> */}
                            {errors.customerId && (
                              <span className="error_valid">
                                {errors.customerId}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Add Pre-care / Aftercare Advice (Optional)
                          </label>
                          <p>
                            You can opt to attach Treatment Advice to the email
                            we send to the client{" "}
                          </p>
                          <input
                            name="msg_for_client"
                            type="search"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Type to search"
                          />
                        </div>
                        <div className="col-lg-12">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Leave a note (optional)
                          </label>
                          <textarea
                            name="msg_for_client"
                            type="text"
                            rows={3}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Type here.. "
                            onChange={handleChange}
                            value={values.msg_for_client}
                          />
                          {errors.msg_for_client && (
                            <span className="error_valid">
                              {errors.msg_for_client}
                            </span>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <label>Required by (Optional)</label>
                          <select
                            style={{ appearance: "auto" }}
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>No option</option>
                            <option value="1">
                              Complete within [x] from sending
                            </option>
                            <option value="2">
                              Complete [x] days before appointment
                            </option>
                          </select>
                          <input
                            name=""
                            type="text"
                            className="form-control "
                            placeholder="Complete within [x] from sending"
                          />
                        </div>
                        {/* <div className="col-lg-12">

                                                        <input
                                                            name="invoice_amount"
                                                            type="number"
                                                            className="form-control wset"
                                                            placeholder="£ Invoice Amount (Optional)"
                                                            onChange={handleChange}
                                                            value={values.invoice_amount}
                                                        />
                                                        {errors.invoice_amount && (
                                                            <span className="error_valid">{errors.invoice_amount}</span>
                                                        )}
                                                    </div> */}

                        {/* <div className="col-lg-12">
                          <input
                            name="tax_amount"
                            className="form-control wset"
                            placeholder="% Tax Amount (Optional)"
                            onChange={handleChange}
                            value={values.tax_amount}
                          />
                          {errors.tax_amount && (
                            <span className="error_valid">
                              {errors.tax_amount}
                            </span>
                          )}
                        </div> */}
                        <div className="col-lg-12">
                          <Row>
                            <Col lg={6} xs={6}>
                              <div className="submit-btn">
                                <button type="submit" class="btn mb-3">
                                  Send Client
                                </button>
                              </div>
                            </Col>
                            <Col lg={6} xs={6}>
                              <div className="cancel-btn">
                                <button
                                  type="button"
                                  onClick={() => props.setModalShow(false)}
                                  class="btn mb-3"
                                >
                                  Cancel
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function SendToClientModal({
  setModalShow,
  modalShow,
  modalType,
  cunsultationId,
}) {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        modalType={modalType}
        cunsultationId={cunsultationId}
      />
    </>
  );
}
