import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { salonfetchClientsAction } from "../Redux/Actions/user/salon";
import { Encryptedid } from "../utils/BcruptEncyptid";
import moment from "moment";

const MyClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState();

  useEffect(() => {
    dispatch(salonfetchClientsAction({ name: search }));
  }, [search]);

  console.log(search, "search");

  const salonClients = useSelector((state) => state.myaccount.salonClients);
  console.log(salonClients);
  return (
    <Layout>
      <section className="margin-top-set">
        <Row>
          <Col lg={5}>
            <div className="cmn-sec-left">
              <h2>Manage Clients</h2>
              <p>Search for, edit and create new clients</p>
            </div>
          </Col>
          <Col lg={7} className="d-flex align-items-end justify-content-end">
            {" "}
            <Row>
              {/* <Col xs={12} lg={12}>
                <div className="main_heading">
                  <h1>All Clients</h1>
                </div>
              </Col> */}
              <Col xs={12} md={7} className="d-flex justify-content-end">
                <div className="search-input">
                  <InputGroup className="mb-3 w-100">
                    <Form.Control
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Member Search"
                    />
                    <InputGroup.Text className="group-box-search">
                      <img
                        src={require("../assets/img/search.svg").default}
                        alt="search"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="srch_result">
                    <p>
                      Showing 1 - {salonClients?.length} of{" "}
                      {salonClients?.length} results
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={5}>
                <div className="button-link">
                  <Nav.Link
                    as={Link}
                    to="/createClient"
                    className="white-box-link"
                  >
                    Create Client
                  </Nav.Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <div className="myclient">
        {/* search header */}
        <section className="search-header client">
          <div className="container">
            <div class="table-responsive">
              <table className="client_table client_page">
                <thead>
                  {" "}
                  <tr>
                    {/* <th>#</th> */}
                    <th className="desktop-view-name">First Name</th>
                    <th className="desktop-view-name">Surname</th>
                    <th className="mob-view-name">Full Name</th>
                    <th className="mobile-none">Contact Number </th>
                    <th className="mobile-none">Email </th>
                    <th>View </th>

                    {/* <th>City</th>
                    <th>First line address</th>
                    <th>Second line address</th>

                    <th>Post code</th> */}
                  </tr>
                </thead>
                <tbody>
                  {salonClients?.map(function (object, i) {
                    return (
                      <tr
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/client_view/${Encryptedid(object?._id)}`)
                        }
                        key={i}
                      >
                        <td className="desktop-view-name">
                          {object?.first_name}
                        </td>
                        <td className="desktop-view-name">
                          {object?.last_name}
                        </td>
                        <td className="mob-view-name">
                          {object?.first_name} {object?.last_name}
                        </td>
                        {/* <td>
                          {(object?.dob &&
                            moment(object?.dob).format("MM/DD/YYYY")) ||
                            "N/A"}
                        </td> */}
                        <td className="mobile-none">0{object?.phone_number}</td>
                        <td className="mobile-none">{object?.email}</td>

                        {/* <td>{object?.city}</td>
                        <td>{object?.first_line_address}</td>
                        <td>{object?.second_line_address}</td>

                        <td>{object?.pin_code}</td> */}
                        <td className="cursor-pointer">View</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        {/* search list */}
      </div>
    </Layout>
  );
};
export default MyClient;
