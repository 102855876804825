import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import useGetAddressIO from "../Hooks/getAddressIo";
import { getProfileById, updateProfile } from "../Redux/Actions/user/salon";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import MyAcoountLayout from "../components/Layout/MyAcoountLayout";

const MyAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addressData, getAddress } = useGetAddressIO();
  const [profileImage, setProfileImage] = useState("");
  const [preview, setPreview] = useState("");
  const [postcode, setPostcode] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [city, setCity] = useState();
  const userProfile = useSelector((state) => state?.myaccount?.userInfo);

  console.log(address1, "userProfile");

  const handleImageUpload = (e, setFieldValue) => {
    if (e.target.files[0]) {
      setFieldValue("salon_profile", e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleKeyEnter = (event) => {
    if (event.key === "Enter") {
      getAddress(postcode);
    }
  };

  useEffect(() => {
    dispatch(getProfileById()).then((data) => {
      if (data?.payload?.data?.postcode) {
        getAddress(data?.payload?.data?.postcode);
      }
    });
  }, []);

  return (
    <MyAcoountLayout>
      <div className="account_form">
        <Formik
          enableReinitialize={true}
          initialValues={{
            salonname: userProfile?.salonname || "",
            postcode: userProfile?.postcode || "",
            address: userProfile?.address || "",
            description: userProfile?.description || "",
            website_url: userProfile?.website_url || "",
            description: userProfile?.description || "",
            contact_no: userProfile?.contact_no || "",
            parking: userProfile?.parking || "",
            child_availability: userProfile?.child_availability || "",
            amenities: userProfile?.amenities || "",
            salon_profile: userProfile?.salon_profile || "",
            gender: userProfile?.gender || "0",
            self_describe: userProfile?.self_describe || "",
            personal_address1: address1 || "",
            personal_address2: address2 || "",
            personal_city: city || "",
          }}
          //validateOnChange={false}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values, profileImage, "values");

            const formData = new FormData();

            for (const key in values) {
              formData.append(key, values[key]);
            }

            dispatch(updateProfile(formData)).then((data) => {
              if (data?.payload?.success) {
                resetForm();
                toast.success(data?.payload?.message);
              }
            });
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2>Salon Profile</h2>
              <p>Update your Salon Details for your clients to view. </p>
              <div className="row">
                <div className="col-lg-4">
                  <label>Salon Name</label>
                  <input
                    className="form-control"
                    placeholder="Salon Name"
                    name="salonname"
                    onChange={handleChange}
                    value={values.salonname}
                  />
                  {errors.salonname && (
                    <span className="error_valid">{errors.salonname}</span>
                  )}
                </div>
                <div className="col-lg-8">
                  <div className="logo_upload">
                    <img
                      src={
                        preview
                          ? preview
                          : values?.salon_profile
                          ? process.env.REACT_APP_HOST_NAME +
                            values?.salon_profile
                          : require("../assets/img/greyrectangle.png")
                      }
                      alt=""
                    />
                    <p>Upload a Logo</p>
                    <input
                      id="input-file"
                      type="file"
                      className="form-control box_shadow_new"
                      onChange={(e) => handleImageUpload(e, setFieldValue)}
                      accept="image/*"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Public Bio</label>
                  <input
                    type="text"
                    className="form-control width_set"
                    id="exampleFormControlInput1"
                    placeholder="Salon Description"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                  />
                  {errors.description && (
                    <span className="error_valid">{errors.description}</span>
                  )}
                </div>
                <div className="col lg-6">
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Post Code Search</label>
                      <input
                        className="form-control"
                        placeholder="Post Code"
                        name="postcode"
                        onChange={(e) => {
                          setPostcode(e.target.value);
                          handleChange(e);
                        }}
                        value={values.postcode}
                      />
                      {errors.postcode && (
                        <span className="error_valid">{errors.postcode}</span>
                      )}
                    </div>
                    <div className="col-lg-6 d-flex align-items-end">
                      <button type="button" onKeyPress={handleKeyEnter}>
                        Search
                      </button>
                    </div>
                    <div className="col-lg-12">
                      <select
                        name="address"
                        class={`form-select ${
                          values.address ? "" : "selected_color"
                        }`}
                        aria-label="Default select example"
                        onChange={(e) => {
                          handleChange(e);
                          const data = addressData?.addresses?.find(
                            (element) =>
                              element?.formatted_address?.toString() ==
                              e.target.value?.toString()
                          );
                          setAddress1(data?.line_1);
                          setAddress2(data?.line_2);
                          setCity(data?.town_or_city);
                        }}
                        value={values.address}
                      >
                        <option>Confirm Address</option>
                        {addressData?.addresses?.map((item) => {
                          return (
                            <option
                              value={item?.formatted_address
                                ?.filter((item) => item !== "")
                                .join(",")}
                            >
                              {item?.formatted_address
                                ?.filter((item) => item !== "")
                                .join(",")}
                            </option>
                          );
                        })}
                      </select>
                      {errors.address && <span>{errors.address}</span>}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <label>Website URL</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Website URL"
                    onChange={handleChange}
                    value={values.website_url}
                    name="website_url"
                  />
                  {errors.website_url && (
                    <span className="error_valid">{errors.website_url}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Contact Number"
                    onChange={handleChange}
                    value={values.contact_no}
                    name="contact_no"
                  />
                  {errors.contact_no && (
                    <span className="error_valid">{errors.contact_no}</span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label>Parking Availability</label>
                  <select
                    class={`form-select ${
                      values.parking ? "" : "selected_color"
                    }`}
                    aria-label="Default select example"
                    onChange={handleChange}
                    value={values.parking}
                    name="parking"
                  >
                    <option>Parking Availability</option>
                    <option value="1"> No Parking Available</option>
                    <option value="2">Free Parking Available</option>
                    <option value="3">Paid Parking</option>
                  </select>
                  {errors.parking && (
                    <span className="error_valid">{errors.parking}</span>
                  )}
                </div>
                <div className="col-lg-12">
                  <label>Parking Availability Notes</label>
                  <textarea
                    rows={3}
                    type="text"
                    className="form-control"
                    placeholder=""
                  />
                </div>
                <div className="col-lg-6">
                  <label>Child Friendliness</label>
                  <select
                    class={`form-select ${
                      values.child_availability ? "" : "selected_color"
                    }`}
                    aria-label="Default select example"
                    onChange={handleChange}
                    value={values.child_availability}
                    name="child_availability"
                  >
                    <option>Child Friendly</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                  {errors.child_availability && (
                    <span className="error_valid">
                      {errors.child_availability}
                    </span>
                  )}
                </div>
                <div className="col-lg-6">
                  <label>Gender</label>

                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="gender"
                    value={values?.gender}
                    onChange={handleChange}
                  >
                    <option value="0">Male</option>
                    <option value="1">Female</option>
                    <option value="2">Non Binary</option>
                    <option value="3">Others </option>
                    <option value="4">Prefer not to say </option>
                  </select>
                  {errors?.gender && (
                    <span className="error">{errors?.gender}</span>
                  )}
                </div>

                {values?.gender == 3 && (
                  <div className="col-lg-6">
                    <label>Self-Describe </label>
                    <input
                      className="form-control"
                      placeholder=""
                      name="self_describe"
                      value={values?.self_describe}
                      onChange={handleChange}
                    />
                    {errors.self_describe && (
                      <span className="error">{errors.self_describe}</span>
                    )}
                  </div>
                )}
                <div className="col-lg-12 ">
                  <label> Amenities</label>
                  <textarea
                    rows={3}
                    className="form-control width_set"
                    id="exampleFormControlInput1"
                    placeholder="Any Other Amenities? (Optional)"
                    name="amenities"
                    onChange={handleChange}
                    value={values.amenities}
                  />
                  {errors.amenities && (
                    <span className="error_valid">{errors.amenities}</span>
                  )}
                </div>
                <div className="submit-btn">
                  <button type="submit" class="btn mb-3">
                    Update
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </MyAcoountLayout>
  );
};

export default MyAccount;
