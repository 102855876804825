import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { Formik } from "formik";
import { editProfile } from "../Redux/Actions/user/auth";
import { useDispatch } from "react-redux";
import useGetAddressIO from "../Hooks/getAddressIo";


const SignUp2 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { addressData, getAddress } = useGetAddressIO();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [city, setCity] = useState();

    const [postcode, setPostcode] = useState();


    const handleKeyEnter = (event) => {
      //  if (event.key === 'Enter') {
            getAddress(postcode).then((data)=>{
               
            });
      //  }
    
    }


  
    return (
        <div className="bg-color">

            {/* Header Start */}
            <Navbar expand="lg" className="bg-body-tertiary header-main">
                <Container>
                    <Navbar.Brand href="#">
                        <img src={require('../assets/img/logo-white.svg').default} alt='logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className="">
                            <p>Already have an account?</p>
                            <Nav.Link href="/signin" className="white-btn">Login</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* Header End */}

            {/* Signup flow Start */}
            <section className="signup-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="signup-inner">
                                <h2>You’re almost there!</h2>
                                <div className="create-acc-form">
                                    <div className="row">
                                        <div className="col-lg-5 pe-0">
                                            <div className="features">
                                                <h6>WITH CONSULTIFY YOU CAN:</h6>
                                                <ul>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                    <li><img src={require('../assets/img/tick.svg').default} alt='tick' /><p>Lorum ipsum</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 ps-0">
                                            <Formik
                                            //enableReinitialize={true}
                                                initialValues={{
                                                    id: params?.id,
                                                    salonname: "",
                                                    postcode: "",
                                                    address: "",
                                                }}
                                                validate={(values) => {
                                                    const errors = {};


                                                    if (!values.salonname) {
                                                        errors.salonname = "Required*";
                                                    }
                                                    if (!values.postcode) {
                                                        errors.postcode = "Required*";
                                                    }
                                                    if (!values.address) {
                                                        errors.address = "Required*";
                                                    }

                                                    return errors;
                                                }}
                                                validateOnChange={false}
                                                onSubmit={(values, { setSubmitting, resetForm }) => {

                                                    dispatch(editProfile({...values, personal_address1: address1 || "",
                                                    personal_address2: address2 || "",
                                                    personal_city: city || "",})).then((data) => {
                                                        if (data?.payload?.success) {
                                                            resetForm();
                                                            navigate(`/signup3/${data?.payload?.data?._id}`)
                                                        }
                                                    });
                                                    setSubmitting(false);
                                                }}
                                            >
                                                {({
                                                    values,
                                                    errors,
                                                    handleChange,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    setFieldValue
                                                }) => (
                                                    <form onSubmit={handleSubmit} className="h-100">
                                                        <div className="create-account create-account-next">
                                                            <ul className="">
                                                                <li className="form-grp">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Your Salon Name"
                                                                        name="salonname"
                                                                        onChange={handleChange}
                                                                        value={values.salonname}
                                                                    />
                                                                    {errors.salonname && (
                                                                        <span className="error_valid">{errors.salonname}</span>
                                                                    )}
                                                                </li>
                                                                <li className="form-grp">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Salon Post Code"
                                                                        name="postcode"
                                                                        onChange={(e) => {
                                                                            setPostcode(e.target.value)
                                                                            handleChange(e)
                                                                        }}
                                                                        //onKeyPress={handleKeyEnter}
                                                                        value={values.postcode}
                                                                    />
                                                                     <button onClick={handleKeyEnter} type="button" >Search</button>
                                                                    {errors.postcode && (
                                                                        <span className="error_valid">{errors.postcode}</span>
                                                                    )}
                                                                </li>
                                                                <li className={addressData?.addresses?.length>0 ? "select-appear" : "form-grp"}>
                                                                    <select
                                                                        name="address"
                                                                        aria-label="Default select example"
                                                                        className="form-control form-select"
                                                                        onChange={(e) => {
                                                                            handleChange(e);
                                                                            const data = addressData?.addresses?.find((element) => element?.formatted_address?.toString() == e.target.value?.toString());
                                                                            setAddress1(data?.line_1);
                                                                            setAddress2(data?.line_2)
                                                                            setCity(data?.town_or_city)
                                                                        }}
                                                                        value={values.address}
                                                                    >
                                                                        <option>Select Address From List</option>
                                                                        {
                                                                            addressData?.addresses?.map((item) => {
                                                                                return (
                                                                                    <option value={
                                                                                        item?.formatted_address?.filter(item => item !== "").join(",")
                                                                                        }>{
                                                                                           item?.formatted_address?.filter(item => item !== "").join(",")
                                                                                            }</option>
                                                                                )
                                                                            })
                                                                        }


                                                                    </select>
                                                                   
                                                                    {/* {errors.address && (
                                                                        <span>{errors.address}</span>
                                                                    )} */}

                                                                </li>
                                                            </ul>

                                                            <div className="submit-btn">
                                                                <button type="submit" className="lg-btn">
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Signup flow End */}
        </div>
    )
}
export default SignUp2;