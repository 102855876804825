import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import MyAcoountLayout from "../components/Layout/MyAcoountLayout";
import {
  consultationformlists,
  getCategory,
  presetconsultationformlistlists,
  updateConsultationForm,
  updatepreConsultationForm,
} from "../Redux/Actions/user/salon";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Encryptedid } from "../utils/BcruptEncyptid";

export default function ConsultationPresents() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState();

  const [search, setSearch] = useState("");
  const consultationform = useSelector(
    (state) => state.myaccount.preconsultationlists
  );
  const [consultationFormData, setConsultationFormData] = React.useState();

  const [categoryselected, setCategoryselected] = useState("");
  const [totalPreconsultation, setTotalPreconsultation] = useState("");

  useEffect(() => {
    dispatch(
      presetconsultationformlistlists({ getdata: "0,1", search: search })
    ).then((data) => {
      if (data?.payload?.data) {
        setConsultationFormData(data?.payload?.data);
        setTotalPreconsultation(data?.payload?.data?.data?.length);
      }
    });
  }, [search]);

  useEffect(() => {
    dispatch(
      getCategory({
        page: 1,
        limit: 1000000000000,
        search: "",
      })
    );
  }, []);
  var category = useSelector((state) => state.myaccount.category);

  var category = category?.category?.map((cat) => {
    const { _id: id1 } = cat;

    // Find the corresponding category count object in consultationform.categoryCounts
    const categoryCount = consultationform?.categoryCounts?.find(
      ({ _id: id2 }) => id2 === id1
    );

    // If categoryCount is found, append the count value to the category object
    if (categoryCount) {
      return {
        ...cat,
        count: categoryCount.count ?? 0, // Append the count value to the category object
      };
    } else {
      return cat; // Return the original category object if no corresponding count is found
    }
  });

  if (categoryselected != "") {
    var arraypreconsultation = consultationform.data?.filter((object) => {
      const categoryId = object?.category?.toString(); // Convert category_id to string (if defined)
      const selectedCategory = categoryselected.toString(); // Convert categoryselected to string

      return categoryId === selectedCategory;
    });
  } else {
    var arraypreconsultation = consultationform.data;
  }

  var handelconsultationformedit = useCallback((id, type) => {
    const encodedEncrypted = Encryptedid(id);

    navigate("/edit-consultation-presets-form?id=" + encodedEncrypted);
  }, []);

  return (
    <MyAcoountLayout>
      <section className="cmn-sec-headings">
        <Row>
          <Col lg={6}>
            <div className="cmn-sec-left">
              <h2>Consultation Presets</h2>
              <p>
                Here you can find pre-defined templates to feature in your
                consultations tab. You can add and remove these at any time.
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            className="d-flex justify-content-end align-items-end mob-btn-set"
          >
            <div className="button-link">
              <NavLink
                to="/consultation-presets-form"
                className="white-box-link  create_white-box-link mt-0 mb-4 "
              >
                Create your own
              </NavLink>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <div className="consultation-presents-tabs">
          <div className="d-flex">
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setCategoryselected(e.target.value);
              }}
            >
              <option value="">All Categories ({totalPreconsultation})</option>
              {category?.map((categoryobject, i) => (
                <option key={i} value={categoryobject?._id}>
                  {categoryobject.title} (
                  {categoryobject?.count == undefined
                    ? 0
                    : categoryobject?.count}
                  )
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="expand-field">
            <InputGroup>
              <InputGroup.Text className="group-box-search">
                <img
                  src={require("../assets/img/search.svg").default}
                  alt="search"
                />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search...."
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </div>
      </section>

      <Row>
        <Col lg={12}>
          <div className="d-flex justify-content-end add-tick">
            <p>Tick box to add</p>
          </div>
        </Col>
      </Row>
      {arraypreconsultation?.map((item, i) => {
        return (
          <section className="search-list-part presents">
            <div className="search-list-box">
              <Row className="d-flex align-items-center">
                <Col xs={12} md={8} sm={8}>
                  <div className="search-content-left">
                    <h2>{item?.form_title}</h2>
                    <div className="sub-heading">
                      {item?.question?.length}{" "}
                      {item?.question?.length == 1 ? "Question" : "Questions"}
                    </div>
                    <p>{item?.form_description}</p>
                  </div>
                </Col>
                <Col
                  xs={8}
                  md={3}
                  sm={3}
                  className="d-flex justify-content-center"
                >
                  <Link
                    to={"/consultation-preset-view/" + Encryptedid(item?._id)}
                  >
                    View
                  </Link>
                </Col>
                <Col
                  xs={4}
                  md={1}
                  sm={1}
                  className="d-flex justify-content-center"
                >
                  <div class="form-check">
                    {item?.formcreatedbyadminPanel?._id != undefined ? (
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={
                          item?.salonActiveArr.find(
                            (obj) =>
                              obj?.salon_id == localStorage.getItem("userId")
                          )?.consulationformstatus == 0
                            ? 1
                            : 0
                        }
                        checked={
                          item?.salonActiveArr.find(
                            (obj) =>
                              obj?.salon_id === localStorage.getItem("userId")
                          )?.consulationformstatus == 0
                        }
                        onChange={() => {
                          dispatch(
                            updatepreConsultationForm({
                              id: item?._id,
                              consulationformstatus:
                                item?.salonActiveArr.find(
                                  (obj) =>
                                    obj?.salon_id ==
                                    localStorage.getItem("userId")
                                )?.consulationformstatus == 0
                                  ? 1
                                  : 0,
                            })
                          );
                        }}
                        id={"flexCheckDefault"}
                      />
                    ) : (
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={item?.status == 0 ? 1 : 0}
                        checked={item?.consulationformstatus == 0}
                        onChange={() => {
                          dispatch(
                            updatepreConsultationForm({
                              id: item?._id,
                              consulationformstatus:
                                item?.consulationformstatus == 0 ? 1 : 0,
                            })
                          );
                        }}
                        id="flexCheckDefault"
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <div className="consultation-box-btns">
                {item?.draft == 0 && <b>Draft</b>}
                <div
                  style={{ cursor: "pointer" }}
                  className="consult-edit"
                  onClick={() => {
                    handelconsultationformedit(item?._id);
                  }}
                >
                  <img src={require("../assets/img/pencil-edit.svg").default} />
                </div>
                {/* Edit */}
                {/* </Link> */}
              </div>
            </div>
          </section>
        );
      })}
    </MyAcoountLayout>
  );
}
