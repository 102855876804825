import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  addConsultationAction,
  editGetdetailsConsultationAction,
} from "../../../Redux/Actions/user/salon";
import { Decryptedid } from "../../../utils/BcruptEncyptid";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";

export default function ConsultationPresetView() {
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [precare, setPrecare] = useState({});

  var idvalue = Decryptedid(atob(id));

  useEffect(() => {
    dispatch(editGetdetailsConsultationAction(idvalue)).then(function (data) {
      if (data.payload) {
        setFormData(data.payload);
      }
    });
  }, [idvalue]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitform = () => {
    console.log(formData, "formData");
    dispatch(addConsultationAction(formData)).then(function () {
      navigate("/consultation-presents");
    });
  };
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const handleClose = () => setShow(false);

  console.log(precare, "precareprecareprecare");

  return (
    <div className="consulation_form three">
      {/* Header Start */}
      <Navbar expand="lg" className="bg-body-tertiary header-blck">
        <Container>
          <Navbar.Brand href="/dashboard">
            <img
              src={require("../../../assets/img/logo.svg").default}
              alt="logo"
            />
          </Navbar.Brand>
          <NavLink className="exit_btn" to="/consultation-presents">
            Exit
          </NavLink>
        </Container>
      </Navbar>
      {/* Header End */}
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="consultationform_tab">
              <h2 className="form_cmn_heading">Consultation View</h2>
              {/* <div className="steps two w-75">
                <Nav.Link href="#">1. Title & Description</Nav.Link>
                <Nav.Link href="#">2. Questions</Nav.Link>
                <Nav.Link href="#">3. Options</Nav.Link>
                <Nav.Link href="#">4. Review</Nav.Link>
              </div> */}
            </div>
          </div>
          {/* <div className="col-lg-3 d-flex align-items-center justify-content-end">
            <div className="draft-set">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  
                />
                <label class="form-check-label" for="flexCheckDefault">
                  Mark As Draft
                </label>
              </div>
            </div>
          </div> */}
        </div>
        {formData?.question?.map(function (question, index) {
          return (
            <div className="form_card" key={index}>
              <h2>{question.optiontype == 5 ? "" : question.question}</h2>

              {(() => {
                if (question.optiontype == 4) {
                  return (
                    <div className="create_new_form">
                      <div className="row">
                        <div className="col-6">
                          <div className="upload_img_div">Upload image</div>
                        </div>
                        <div className="col-6">
                          <div className="upload_img_div">Upload image</div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (question.optiontype == 5) {
                  return (
                    <div className="declaration">
                      <label>Declaration</label>
                      <p style={{ whiteSpace: "pre-line" }}>
                        {question.question}
                      </p>
                      <div className="d-flex justify-content-end">
                        <label>{question.confirmation || "I Confirm"} </label>
                        <input type="checkbox" />
                      </div>
                    </div>
                  );
                } else if (question.optiontype == 6) {
                  return (
                    <div className="sign_box">
                      <p>{question.question}</p>
                    </div>
                  );
                }
              })()}

              {question.optiontype == 2 ? (
                <div className="options_form">
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Enter your answer here"
                  ></textarea>
                </div>
              ) : (
                (question.optiontype == 0 ||
                  question.optiontype == 1 ||
                  question.optiontype == 3) && (
                  <div className="options_form">
                    <div
                      className={
                        question.optiontype == 3
                          ? "d-flex justify-content-between  create_new_form"
                          : " "
                      }
                    >
                      {question?.options?.map(function (option, optionindex) {
                        return (
                          <div
                            className="form-check"
                            key={optionindex}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={
                              option?.formOptiontitle == 1
                                ? "Proceed"
                                : option?.formOptiontitle == 2
                                ? "Cannot Proceed"
                                : option?.formOptiontitle == 3 &&
                                  "Proceed With Message"
                            }
                          >
                            {(() => {
                              if (question.optiontype == 3) {
                                return (
                                  <img
                                    src={
                                      option[`imagename${optionindex + 1}`] !=
                                      ""
                                        ? process.env.REACT_APP_HOST_NAME +
                                          "/Adminquestionimage" +
                                          option[`imagename${optionindex + 1}`]
                                        : option[`imageurl${optionindex + 1}`]
                                    }
                                    alt={option.imageurl1}
                                    className="option_image"
                                  />
                                );
                              } else {
                                return (
                                  <>
                                    <input
                                      className="form-check-input"
                                      type={
                                        question.optiontype === "0"
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexRadioDefault1"
                                    >
                                      {option?.optiontitle}
                                    </label>
                                  </>
                                );
                              }
                            })()}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              )}
            </div>
          );
        })}

        {formData?.pre_care_setarray?.map(function (object, i) {
          return (
            <section className="search-list-part">
              <div className="search-list-box">
                <Row className="d-flex align-items-end" key={i}>
                  <Col xs={4} md={9} sm={9}>
                    <div className="search-content-left">
                      <h2>{object?.pre_care_id?.treatmentname}</h2>
                      <div className="sub-heading">
                        Pre & Post Treatment Care Advice
                      </div>
                      <p>{object?.pre_care_id?.description}</p>
                    </div>
                  </Col>

                  <Col
                    xs={8}
                    md={3}
                    sm={3}
                    className="d-flex justify-content-center"
                  >
                    <Link
                      to=""
                      className="consult-view-btn"
                      onClick={() => {
                        setPrecare(object?.pre_care_id);
                        setShow(true);
                      }}
                    >
                      View
                    </Link>
                  </Col>
                </Row>
              </div>
            </section>
          );
        })}
      </div>
      <Modal show={show} onHide={handleClose} className="client-consult">
        <Modal.Body>
          <div className="treat-box h-100">
            <h2 className="text-center">{precare.treatmentname}</h2>
            <Row>
              <Col lg={6}>
                <div className="left-instruct">
                  <h3>Pre Care Instructions</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>
                      {precare.pre_care_advice}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="right-instruct">
                  <h3>Post Care Instructions</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>
                      {precare.after_care_advice}
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="right-instruct text-center">
                  <h3>Notes</h3>
                  <div className="instruct-inner-scroll">
                    <p style={{ textWrap: "pre-line" }}>{precare.notes}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
